@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster";

.app {
  text-align: center;
  height: 100%;
}

.cluster-point {
  background: #5bb540;
  border: 1px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.custom-leaflet-popup .leaflet-popup-content-wrapper {
  opacity: 0.9;
  border-radius: 2%;
}

.leaflet-map-z-override {
  z-index: 9990;
}
